.footer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 40vh;
  /* margin-top: 80px; */
  /* margin-bottom: 80px; */
}

.text_copyright{
  font-size: clamp(1.2rem, 2.5vw, 2rem);
  /* font-weight: 700; */
  margin-bottom: 2rem;
  color: #fff;
  text-align: center;
  margin: 1rem auto;
}

.footer-container p {
  /* font-family: "Open Sans Condensed", sans-serif; */
  font-weight: 300;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: #fff;
  text-align: center;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
  max-width: 400px;
  /* border: 1px solid red; */
}

@media (max-width: 420px) {
  .footer-container p {
    max-width: 300px;
  }
}

.footer-container h2 {
  font-family: "Open Sans Condensed";
  font-weight: 100;
  font-size: 20px;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  border-radius: 5px;
}
.footer-container h2:hover {
  background-color: rgba(0, 0, 0, 0.068);
}

.social-links {
  padding: 10px;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.social-links .social {
  height: 45px;
  width: 45px;
  background-color: rgba(0, 0, 0, 0.548);
  margin: 0 5px;
  padding: 10px;
  backdrop-filter: blur(10px);
  cursor: pointer;
  transition: background 0.2s ease;
}

.social-links .social:hover {
  background-color: rgba(255, 255, 255, 0.336);
  fill: var(--color-pri);
}
