.contact-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  margin-top: 100px;
  padding: 50px;
}

.contact-container h1 {
  margin: 0 auto;
  font-size: clamp(2.5rem, 2.5vw, 2.5rem);
  /* font-family: 'Open Sans Condensed'; */
  font-weight: 600;
  color: var(--color-regent);
  margin-bottom: 2rem;
}

.title_contact{
  margin: 0 auto;
  font-size: clamp(2.5rem, 2.5vw, 4rem);
  /* font-family: 'Open Sans Condensed'; */
  font-weight: 600;
  color: var(--color-regent);
  margin-bottom: 2rem;
}

.call_us{
  font-size: clamp(1.2rem, 2.5vw,2rem); 
  color: #fff;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1.2rem;
}

.email_us{
  font-size: clamp(1.2rem, 2.5vw, 2rem);
  /* font-weight: 700; */
  margin-bottom: 1.2rem;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}

.address_us{
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  /* font-weight: 700; */
  margin-bottom: 2rem;
  color: #fff;
  text-align: center;
  margin: 1rem auto;
}

.img_us{
  margin: 1rem auto;
  width: 350px;
  height: 350px;
  object-fit: cover;
  border-radius: 10px;
}


.contact-container .contact-links {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.contact-container .contact {
  position: inherit;
  height: 280px;
  width: 30%;
  background-color: rgba(0, 0, 0, 0.322);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease, transform 0.2s ease;
}

.contact-container .contact:hover {
  background-color: rgba(255, 255, 255, 0.096);
  transform: scale(1.1);
}

.contact-container .contact .icon {
  position: relative;
  font-size: 130px;
}

.contact-container .contact h2 {
  position: relative;
  margin-top: 15px;
  font-size: 21px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  font-family: 'Open Sans';
  text-align: center;
}

.contact-container .contact h2 span {
  font-size: 13px;
  font-weight: 100;
  margin-top: 3px;
  color: rgb(202, 202, 202);
}

@media (max-width: 820px) {
  .contact-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 80vh;
    margin-top: 100px;
    padding: 10px;
  }

  /* .contact-container h1 {
    margin: 0 auto;
    font-size: 40px;
    font-family: 'Open Sans Condensed';
    font-weight: 400;
    color: var(--color-pri);
  } */

  .contact-container .contact-links {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  .contact-container .contact {
    position: inherit;
    height: 400px;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.322);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;
    backdrop-filter: blur(20px);
    transition: background 0.3s ease, transform 0.2s ease;
  }
  .contact-container .contact .icon {
    position: relative;
    font-size: 200px;
  }

  .contact-container .contact h2 {
    position: relative;
    margin-top: 15px;
    font-size: 30px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    font-family: 'Open Sans';
    text-align: center;
  }

  .contact-container .contact h2 span {
    font-size: 25px;
    font-weight: 100;
    margin-top: 3px;
    color: rgb(202, 202, 202);
  }
}

@media (max-width: 500px) {
  .contact-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 80vh;
    margin-top: 100px;
    padding: 50px;
  }

  /* .contact-container h1 {
    margin: 0 auto;
    font-size: 20px;
    font-family: 'Open Sans Condensed';
    font-weight: 400;
    color: var(--color-pri);
  } */

  .contact-container .contact-links {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  .contact-container .contact {
    position: inherit;
    height: 280px;
    width: 95%;
    background-color: rgba(0, 0, 0, 0.322);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;
    backdrop-filter: blur(20px);
    transition: background 0.3s ease, transform 0.2s ease;
  }
  .contact-container .contact .icon {
    position: relative;
    font-size: 130px;
  }

  .contact-container .contact h2 {
    position: relative;
    margin-top: 15px;
    font-size: 21px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    font-family: 'Open Sans';
    text-align: center;
  }

  .contact-container .contact h2 span {
    font-size: 13px;
    font-weight: 100;
    margin-top: 3px;
    color: rgb(202, 202, 202);
  }
}

@media (max-width: 420px) {
  .contact-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 80vh;
    margin-top: 70px;
    padding: 30px;
  }

  .contact-container h1 {
    margin: 0 auto;
    font-size: 25px;
    /* font-family: 'Open Sans Condensed'; */
    font-weight: 400;
    color: var(--color-pri);
  }

  .contact-container .contact-links {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  .contact-container .contact {
    position: inherit;
    height: 280px;
    width: 95%;
    background-color: rgba(0, 0, 0, 0.322);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;
    backdrop-filter: blur(20px);
    transition: background 0.3s ease, transform 0.2s ease;
  }
  .contact-container .contact .icon {
    position: relative;
    font-size: 150px;
  }

  .contact-container .contact h2 {
    position: relative;
    margin-top: 15px;
    font-size: 25px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    font-family: 'Open Sans';
    text-align: center;
  }

  .contact-container .contact h2 span {
    font-size: 15px;
    font-weight: 100;
    margin-top: 3px;
    color: rgb(202, 202, 202);
  }
}

@media (max-width: 375px) {
  .contact-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 80vh;
    margin-top: 100px;
    padding: 15px;
  }

  .contact-container h1 {
    margin: 0 auto;
    font-size: 22px;
    font-family: 'Open Sans Condensed';
    font-weight: 400;
    color: var(--color-pri);
  }

  .contact-container .contact-links {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  .contact-container .contact {
    position: inherit;
    height: 290px;
    width: 95%;
    background-color: rgba(0, 0, 0, 0.322);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    backdrop-filter: blur(20px);
    transition: background 0.3s ease, transform 0.2s ease;
  }
  .contact-container .contact .icon {
    position: relative;
    font-size: 150px;
  }

  .contact-container .contact h2 {
    position: relative;
    margin-top: 15px;
    font-size: 25px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    font-family: 'Open Sans';
    text-align: center;
  }

  .contact-container .contact h2 span {
    font-size: 15px;
    font-weight: 100;
    margin-top: 3px;
    color: rgb(202, 202, 202);
  }
}
