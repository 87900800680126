.home-container {
  position: relative;
  min-height: 100vh;
  padding: 50px 40px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-container .logo {
  position: inherit;
  margin-top: 50px;
  height: 520px;
  width: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  user-select: none;
  /* border: 2px solid red; */
}

.home-container .logo .hover-show {
  position: absolute;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  cursor: pointer;
}

.home-container .logo .hover-show.active {
  animation: showspin 8s linear 1s infinite;
}

.font_welcome {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin-top: 2rem;
  background: -webkit-linear-gradient(135deg, #fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 100%;
  text-align: center;
  letter-spacing: 0px;
}

.font_gradient {
  font-size: clamp(2.3rem, 6vw, 4.5rem);
  margin-bottom: 0rem;
  background: -webkit-linear-gradient(45deg, #fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 100%;
  text-align: center;
  letter-spacing: 4px;
  font-weight: 600;
}

.font_text {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin: 2rem;
  background: -webkit-linear-gradient(135deg, #fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 100%;
  text-align: center;
  letter-spacing: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}

.cta {
  position: relative;
  margin: auto;
  padding: 19px 22px;
  transition: all 0.2s ease;
}
.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: rgba(255, 171, 157, 0.5);
  width: 56px;
  height: 56px;
  transition: all 0.3s ease;
}
.cta span {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  vertical-align: middle;
  color: #fff;
}
.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #fff;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}
.cta:hover:before {
  width: 100%;
  background: #ffab9d;
}
.cta:hover svg {
  transform: translateX(0);
}
.cta:active {
  transform: scale(0.96);
}


@keyframes showspin {
  to {
    transform: rotate(360deg);
  }
}

.home-container .logo .circle {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: rgb(4, 4, 44);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
  opacity: 0;
}

.home-container .logo .hover-show.active .circle {
  opacity: 1;
}

.home-container .logo .hover-show.active .circle:nth-child(1) {
  background: url(../props/contact1.jpg);
  background-size: cover;
  background-position: center;
  position: absolute;
  transform: translateY(-210%);
}
.home-container .logo .hover-show.active .circle:nth-child(2) {
  position: absolute;
  transform: translateX(150%) translateY(-150%);
  background: url(../props/contact2.jpg);
  background-size: cover;
  background-position: center;
}
.home-container .logo .hover-show.active .circle:nth-child(3) {
  position: absolute;
  transform: translateX(210%);
  background: url(../props/contact3.jpg);
  background-size: cover;
  background-position: center;
}
.home-container .logo .hover-show.active .circle:nth-child(4) {
  position: absolute;
  transform: translateX(150%) translateY(150%);
  background: url(../props/contact4.jpg);
  background-size: cover;
  background-position: center;
}
.home-container .logo .hover-show.active .circle:nth-child(5) {
  position: absolute;
  transform: translateY(210%);
  background: url(../props/contact5.jpg);
  background-size: cover;
  background-position: center;
}
.home-container .logo .hover-show.active .circle:nth-child(6) {
  position: absolute;
  transform: translateX(-150%) translateY(150%);
  background: url(../props/contact6.jpg);
  background-size: cover;
  background-position: center;
}
.home-container .logo .hover-show.active .circle:nth-child(7) {
  position: absolute;
  transform: translateX(-210%);
  background: url(../props/contact8.jpg);
  background-size: cover;
  background-position: center;
}
.home-container .logo .hover-show.active .circle:nth-child(8) {
  position: absolute;
  transform: translateX(-150%) translateY(-150%);
  background: url(../props/contact9.jpg);
  background-size: cover;
  background-position: center;
}

.home-container img {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 1px solid #a5836c;
  transform: translate(-200px);
  opacity: 0;
  animation: fade-in 1s ease 1 0.5s forwards;
}

@keyframes fade-in {
  to {
    transform: translate(0);
    opacity: 1;
  }
}

.home-container h2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  margin-top: 50px;
}

.home-container h2 span {
  font-size: 25px;
  opacity: 0.8;
  padding-bottom: 5px;
  border-bottom: 2px solid var(--color-pri);
  font-family: "Open Sans Condensed", sans-serif;
}

.home-container h2 p {
  font-size: 20px;
  opacity: 1;
  font-weight: 100;
  margin-top: 1px;
  font-family: "Open Sans", sans-serif;
}

.scroll-down {
  transform: rotate(90deg);
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.scroll-down hr {
  background-color: blue;
  width: 20px;
}

.scroll-down h5 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 100;
}

.scroll-down .scroll {
  transform: rotate(-90deg);
  font-size: 20px;
}

@media (max-width: 820px) {
  .home-container {
    position: relative;
    min-height: 100vh;
    padding: 150px 40px;
    margin-top: 00px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .home-container .logo {
    position: inherit;
    height: 220px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    user-select: none;
  }

  .home-container .logo .hover-show {
    position: absolute;
    height: 220px;
    width: 220px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    cursor: pointer;
  }

  .home-container .logo .circle {
    position: absolute;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: rgb(4, 4, 44);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    opacity: 0;
  }

  .home-container img {
    position: absolute;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    border: 1px solid #a5836c;
    transform: translate(-200px);
    opacity: 0;
    animation: fade-in 1s ease 1 0.5s forwards;
  }

  .home-container h2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin-top: 200px;
  }

  .home-container h2 span {
    font-size: 25px;
    opacity: 0.8;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--color-pri);
    font-family: "Open Sans Condensed", sans-serif;
  }

  .home-container h2 p {
    font-size: 25px;
    font-weight: 100;
    font-family: "Open Sans", sans-serif;
  }
}

@media (max-width: 500px) {
  .home-container {
    position: relative;
    min-height: 100vh;
    padding: 150px 30px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .home-container .logo {
    position: inherit;
    height: 220px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    user-select: none;
  }

  .home-container .logo .hover-show {
    position: absolute;
    height: 220px;
    width: 220px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    cursor: pointer;
  }

  .home-container .logo .circle {
    position: absolute;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-color: rgb(4, 4, 44);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    opacity: 0;
  }

  .home-container img {
    position: absolute;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 1px solid #a5836c;
    transform: translate(-200px);
    opacity: 0;
    animation: fade-in 1s ease 1 0.5s forwards;
  }

  .home-container h2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin-top: 200px;
  }

  .home-container h2 span {
    font-size: 22px;
    opacity: 0.8;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--color-pri);
    font-family: "Open Sans Condensed", sans-serif;
  }

  .home-container h2 p {
    font-size: 18px;
    font-weight: 100;
    font-family: "Open Sans", sans-serif;
  }
  .scroll-down {
    transform: rotate(90deg);
    position: absolute;
    right: -70px;
    top: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}

@media (max-width: 400px) {
  .home-container {
    position: relative;
    min-height: 100vh;
    padding: 10px 10px 0;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .home-container .logo {
    position: inherit;
    height: 180px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    user-select: none;
    margin-bottom: 5rem;
  }

  .home-container .logo .hover-show {
    position: absolute;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    cursor: pointer;
  }

  .home-container .logo .circle {
    position: absolute;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    background-color: rgb(4, 4, 44);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    opacity: 0;
  }

  .home-container img {
    position: absolute;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 1px solid #a5836c;
    transform: translate(-200px);
    opacity: 0;
    animation: fade-in 1s ease 1 0.5s forwards;
  }

  .home-container h2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin-top: 00px;
  }

  .home-container h2 span {
    font-size: 22px;
    opacity: 0.8;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--color-pri);
    font-family: "Open Sans Condensed", sans-serif;
  }

  .home-container h2 p {
    font-size: 18px;
    font-weight: 100;
    font-family: "Open Sans", sans-serif;
  }
}
