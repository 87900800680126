.navigation {
  position: fixed;
  left: 0px;
  top: 50%;
  transform: translateY(-50%) translateX(-100px);
  background-color: rgba(0, 0, 0, 0.596);
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23px;
  border-radius: 50px;
  opacity: 0;
  animation: fade-nav-in 1s ease 1 1s forwards;
  z-index: 999;
}

@keyframes fade-nav-in {
  to {
    transform: translateY(-50%) translateX(0);
    opacity: 1;
  }
}
.navigation a {
  position: relative;
  background-color: rgba(0, 0, 0, 0.397);
  border-radius: 50%;
}

.icon {
  font-size: 50px;
  border-radius: 50%;
  padding: 10px;
  transition: fill 0.1s ease;
  fill: #fff;
}

.navigation .icon.active-nav {
  fill: #a5836c;
  border: 2px solid #a5836c;
}

.navigation .icon:hover {
  fill: rgba(88, 117, 248, 0.151);
  border: 2px solid rgba(88, 117, 248, 0.192);
}

.navigation .icon.active-nav:hover {
  fill: #a5836c;
  border: 2px solid #a5836c;
}

@media (max-width: 820px) {
   .navigation {
    position: fixed;
    z-index: 1000;
    backdrop-filter: blur(30px);
    left: 50%;
    transform: translateX(-50%) translateY(100px);
    opacity: 0;
    top: 88%;
    background-color: rgba(0, 0, 0, 0.596);
    padding: 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 23px;
    border-radius: 100px;
    animation: fade-nav-bottom 1s ease forwards;
  }

  @keyframes fade-nav-bottom {
    to {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }

  .navigation a {
    position: relative;
    background-color: rgba(0, 0, 0, 0.397);
    border-radius: 50%;
  }

  .icon {
    font-size: 70px;
    border-radius: 50%;
    padding: 10px;
    transition: fill 0.1s ease;
    fill: rgb(209, 217, 252);
  } 
}

@media (max-width: 500px) {
   .navigation {
    position: fixed;
    z-index: 1000;
    backdrop-filter: blur(30px);
    left: 50%;
    transform: translateX(-50%) translateY(100px);
    opacity: 0;
    top: 88%;
    background-color: rgba(0, 0, 0, 0.596);
    padding: 12px 20px;
    max-width: 99%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 23px;
    border-radius: 50px;
    animation: fade-nav-bottom 1s ease forwards;
  }

   @keyframes fade-nav-bottom {
    to {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }

  .navigation a {
    position: relative;
    background-color: rgba(0, 0, 0, 0.397);
    border-radius: 50%;
  }

  .icon {
    font-size: 50px;
    border-radius: 50%;
    padding: 10px;
    transition: fill 0.1s ease;
    fill: rgb(209, 217, 252);
  } 
}
